import {writable} from 'svelte/store';
import {originURL} from 'Utility/fetchAuth';

export interface AppContext {
  redirectTo: (url: string) => void;
}

export const API_HOST_V2_URL = originURL + '/api';

export const sveltekitContext = writable<boolean>(false);
